
import request from '@/utils/request'

export const generate = data =>
    request.post('mrstage-sw/digital/generate', data)

export const getDigitalMan = data =>
    request.get(`mrstage-sw/digital/getDigitalMan/${data}`)

export const queAnswers = data =>
    request.post('mrstage-sw/zjDigital/queAnswers', data)
export const getAreaInfo = data =>
    request.get('/mrstage-base/sence/getAreaInfo/area/' + data)

export const startPlay = data =>
    // request.post('/api/2dvh/v1/material/live/channel/start', data)
    request.post('/mrstage-sw/shangtang/start/' + data)

export const validAccessToken = data =>
    request.post('/mrstage-sw/shangtang/validAccessToken', data)


export const Livetakeover = data =>
    // request.post('/api/2dvh/v1/material/live/channel/command', data)
    request.post('/mrstage-sw/shangtang/command', data)

export const qnyCreatevideo = data =>
    // request.post('/api/2dvh/v1/material/live/channel/command', data)
    request.post('/mrstage-sw/shangtang/create', data)

export const heartbeat = data =>
    // request.post('/api/2dvh/v1/material/live/channel/command', data)
    request.post(`/mrstage-sw/shangtang/heartbeat/${data}`)


export const shuzirenclose = data =>
    // request.post('/api/2dvh/v1/material/live/channel/command', data)
    request.post(`/mrstage-sw/shangtang/close/${data}`)

export const wordToPicture = data =>
    // request.post('/api/2dvh/v1/material/live/channel/command', data)
    request.post(`/mrstage-sw/picture/wordToPicture`, data)

export const createImage = data =>
    // request.post('/api/2dvh/v1/material/live/channel/command', data)
    request.post(`/mrstage-chat/chatImage/createImage`, data)

export const getVideoStatus = data =>
    // request.post('/api/2dvh/v1/material/live/channel/command', data)
    request.get('/mrstage-sw/digitalMaterialResult/findResultBySessionId/' + data)

export const qnyCreateV2 = data =>
    request.post('/mrstage-sw/shangtang/start/v2', data)

export const heartbeatBySessionId = data =>
    // request.post('/api/2dvh/v1/material/live/channel/command', data)
    request.post(`/mrstage-sw/shangtang/heartbeatBySessionId/${data}`)


export const videogetDigital = data =>
    request.get(`mrstage-base/video/getDigital`)
export const sendVideo = data =>
    request.post(`mrstage-base/video/sendVideo`, data)
export const getVideoResult = data =>
    request.get(`mrstage-base/video/getVideoResult/${data}`)

export const ttslist = data =>
    request.post(`mrstage-sw/shangtang/tts/list`, data)

export const uploadFileupload = data =>
    request.post(`/mrstage-base/uploadFile/upload`, data)

export const uploadFilemerge = data =>
    request.get('/mrstage-base/uploadFile/merge/' + data)

