import axios from 'axios'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, 
    timeout: 10800000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json'
        config.headers['Token'] = localStorage.getItem('Token')
        config.headers['Authorization'] = 'Bearer '+localStorage.getItem('token')
        if (config.method === 'get') {
            config.data = true
        }
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        return Promise.reject(error)
    }
)

export default service
